import React from "react"
import CLink from "../cLink"
import Image from "../imagev2"
import Arrow from "../vector/arrow"
import tw, { css } from "twin.macro"
import Animacion from "../mini/animacion"
import EnlaceInternoEnColumna from "../mini/enlaceInternoEnColumna"
import htmlFilters from "../../helpers/htmlFilters";
const Columnas = ({ content }) => {
  const { data } = content

  const Texto = ({ block }) => (
    <div
      css={[
        tw`py-4 text-obsmall2 md:text-obmed2 leading-5 md:leading-6 text-gris1  dark:text-gris3`,
        cssTexto,
      ]}
    >{htmlFilters(block.texto_con_formato1)}</div>
  )
  let columnasContenidos = []
  const blocksCopy = JSON.parse(JSON.stringify(data.blocks))
  const blocksOrdered = data.checkbox3 ? blocksCopy.reverse() : blocksCopy
  blocksOrdered.forEach((block, i) => {
    const columna = {
      align: block.align,
      content: null,
    }
    switch (block.type) {
      case "imagen":
        columna.content = (
          <div css={tw`relative`}>
            {block.url?.length > 1 ? (
              <CLink to={block.url}>
                <Image
                  objectFit={"contain"}
                  imageTablet={block.image}
                  widths={["880px", "440px", "880px"]}
                  // aspectRatio={block.image.aspectratio}
                  // alt={block.image.description}
                  // base64={block.image.base64}
                  // src={block.image.url880}
                  // srcSet={`${block.image.url220} 220w, ${block.image.url440} 440w, ${block.image.url880} 880w, ${block.image.url980} 980w, ${block.image.url1760} 1200w, ${block.image.url2000} 2000w`}
                />
                <div css={tw`absolute bottom-5 right-6 uppercase text-right`}>
                  <div
                    css={[
                      tw`block text-sm font-bold `,
                      block.checkbox1 ? tw`text-white` : tw`text-marron1`,
                    ]}
                  >
                    {block.texto_corto1}
                    <br />
                    <div css={tw`-mr-3`}>
                      <Arrow color={block.checkbox1 ? "#ffffff" : "#847360"} />
                    </div>
                  </div>
                </div>
              </CLink>
            ) : (
              <Image
                objectFit={"contain"}
                imageTablet={block.image}
                widths={["880px", "440px", "880px"]}
              />
            )}
          </div>
        )
        break
      case "texto":
        columna.content = (
          <>
            {block.url?.length > 1 && (
              <div css={[data.checkbox2 && tw`hidden md:block`]}>
                <EnlaceInternoEnColumna
                  url={block.url}
                  title={block.texto_corto1}
                />
              </div>
            )}
            <Texto block={block} i={i} />
          </>
        )
        break
      case "media_y_texto":
        columna.content = (
          <div>
            {block.image && (
              <Image
                objectFit={"contain"}
                imageTablet={block.image}
                widths={["880px", "440px", "880px"]}
              />
            )}
            {block.texto_corto1 && (
              <div
                css={tw`relative w-full h-0 overflow-hidden`}
                style={{ paddingBottom: "56.25%" }}
              >
                <iframe
                  css={tw`absolute w-full h-full top-0 left-0`}
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${block.texto_corto1}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
            <Texto block={block} i={i} />
          </div>
        )
        break

      case "animacion":
        if (block.checkbox1) {
          //Si es animación cuadrada centrada (Abra a tu estilo)
          columna.content = (
            <CLink css={tw`relative block pt-100x100 bg-white`} to={block.url}>
              <div
                css={tw`absolute left-1/2 top-1/2 w-1/2 h-1/2 transform -translate-x-1/2 -translate-y-1/2`}
              >
                <Animacion animationData={block.texto_sin_formato1} />
              </div>
              {block.url.length > 3 && (
                <div
                  css={tw`absolute p-2 left-0 md:left-auto right-0 bottom-0`}
                  // css={tw`absolute left-0 bottom-0 w-full h-1/4 flex justify-center items-center`}
                >
                  <div css={tw`text-marron1 flex justify-center items-center`}>
                    <span css={tw`block pb-1 text-obsmall md:text-obmed2`}>
                      {block.title}
                    </span>
                    <span css={tw`block ml-2`}>
                      <Arrow color="#847360" />
                    </span>
                  </div>
                </div>
              )}
            </CLink>
          )
        } else {
          columna.content = (
            <CLink css={tw`relative`} to={block.url}>
              <div css={tw`relative bg-white`}>
                <div css={cssAnimacionNoCuadradaContenedor}>
                  <Animacion
                    twStyles={cssAnimacionNoCuadrada}
                    animationData={block.texto_sin_formato1}
                  />
                </div>
                {block.url.length > 3 && (
                  <div
                    css={tw`md:absolute md:bottom-5 md:right-6 pr-6 md:pr-0 py-2 md:py-0 uppercase text-right`}
                  >
                    <div css={tw`block text-sm font-bold text-marron1`}>
                      {block.title}
                      <br />
                      <div css={tw`-mr-3`}>
                        <Arrow
                          color={block.checkbox1 ? "#ffffff" : "#847360"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CLink>
          )
        }

        break
      case "vacio":
        columna = <div />
        break
      default:
        break
    }
    columnasContenidos.push(columna)
  })
  const nColumns = data.numero1 ? data.numero1 : 2
  return (
    <div
      css={[
        tw`pb-0 md:pb-1 md:grid`,
        cssColumns,
        data.checkbox3 && cssColumnsInverted,
        data.checkbox1 ? tw`md:gap-7` : tw`md:gap-1`,
      ]}
    >
      {columnasContenidos.map((columna, i) => {
        return (
          <div
            key={i}
            css={[
              tw`mb-1 md:mb-0`,
              columna.align === "ABAJO" && tw`self-end`,
              columna.align === "CENTRADO" && tw`self-center`,
              // cells[`c${i + 1}`],
            ]}
          >
            {columna.content}
          </div>
        )
      })}
    </div>
  )
}

const cssTexto = css`
  h3,
  h4,
  h5,
  h6 {
    ${tw`pb-4`}
    ${tw`text-obsmall md:text-sm`}
        ${tw`uppercase`}
        ${tw`font-bold`}
        ${tw`dark:text-gris3`}
  }
  p {
    ${tw`pb-4`}
    ${tw`dark:text-gris3`}
  }
  a {
    ${tw`underline`}
    ${tw`text-marron1`}
  }
`
const cssAnimacionNoCuadrada = css`
  @media (min-width: 768px) {
    position: absolute;
    top: -4rem;
    bottom: -2rem;
    left: -2rem;
    width: auto;
  }
`
const cssAnimacionNoCuadradaContenedor = css`
  ${tw`pointer-events-none`}
  position: relative;
  @media (min-width: 768px) {
    padding-top: 56%;
  }
`
const cssColumns = css`
  grid-template-columns: 1fr 1fr;
`
const cssColumnsInverted = css`
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
`
// const cssColumns = {
//   columns2: css`
//     grid-template: "a b";
//     ${tw`md:grid-cols-2`}
//   `,
//   columns3: css`
//     grid-template: "a b c";
//     ${tw`md:grid-cols-3`}
//   `,
//   columns4: css`
//     grid-template: "a b c d";
//     ${tw`sm:grid-cols-2 md:grid-cols-4`}
//   `,
// }
// const cssColumnsInverted = {
//   columns2: css`
//     grid-template: "b a";
//   `,
//   columns3: css`
//     grid-template: "c b a";
//   `,
//   columns4: css`
//     grid-template: "d c b a";
//   `,
// }

// const cells = {
//   c1: css`
//     grid-area: a;
//   `,
//   c2: css`
//     grid-area: b;
//   `,
//   c3: css`
//     grid-area: c;
//   `,
//   c4: css`
//     grid-area: d;
//   `,
// }

// const Cocina = ({block, windowSize}) => {
//     const [ hover, setHover ] = React.useState(false)

//     const backgroundSpring = useSpring({
//         x: windowSize.width > 767 && hover ? '-6rem' : '-40rem',
//         y: windowSize.width > 767 && hover ? '16rem' : '10rem',
//         rotate: windowSize.width > 767 && hover ? '45deg' : '70deg',
//         }
//     )
//     const textSpring = useSpring({

//         opacity: windowSize.width > 767 && hover ? 1 : 0,
//         }
//     )

//     return (
//         <li>
//                 <CLink
//                     to={block.url}
//                     css={tw`block relative mt-2 overflow-hidden`}
//                     onMouseEnter={() => {setHover(true)}}
//                     onMouseLeave={() => {setHover(false)}}
//                 >
//                     <Image
//                         aspectRatio={block.image.aspectratio}
//                         alt={block.image.description}
//                         base64={block.image.base64}
//                         src={block.image.url}
//                         srcSet={`${block.image.url} 220w, ${block.image.url} 440w, ${block.image.url} 880w, ${block.image.url} 980w, ${block.image.url} 1200w, ${block.image.url} 2000w`}
//                     />
//                     <span //mobile background
//                         css={tw`block md:hidden absolute inset-0 top-0 px-5 py-3 bg-bggrisimage z-10`}
//                     />
//                     <a.span //tablet background
//                         css={tw`hidden md:block absolute -inset-60 bg-bggrisimage2 z-10`}
//                         style={backgroundSpring}
//                     />
//                     <a.div
//                         css={tw`block absolute inset-0 top-0 md:top-auto md:bottom-0 px-5 py-3 z-20`}
//                         style={textSpring}
//                     >
//                         <span
//                             dangerouslySetInnerHTML={{__html: block.title}}
//                             css={tw`text-sm md:text-base uppercase text-white font-light z-10 mr-4`}
//                         />
//                         <span
//                             css={tw`block md:inline-block md:align-middle`}
//                         >
//                             <Arrow />
//                         </span>
//                     </a.div>

//                 </CLink>
//             </li>
//     )
// }

export default Columnas
